<template>
  <v-row v-if="loading" align="center" justify="center" class="fill-height">
    <v-progress-circular
      :size="60"
      :width="5"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-row>

  <v-container v-else class="fill-height">
    <v-tabs
      v-model="selectedTab"
      class="tabs-border mb-4"
      color="gray13"
      background-color="transparent"
      height="46"
    >
      <v-tab
        v-for="item in $t('panel.settingsPage.tabs')"
        :key="item.id"
        :ripple="false"
        @change="selectedTab = item.id"
      >
        <span
          class="font-medium-16 gray7--text"
          :class="{ 'gray13--text': selectedTab === item.id }"
          >{{ item.title }}</span
        >
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab" class="settings-tab fill-width">
      <transition mode="out-in" name="route">
        <component :is="tabComponentName" />
      </transition>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { UserInfoService } from "@/services";
import { mapGetters, mapActions } from "vuex";
const AccountInfo = () =>
  import(
    /* webpackChunkName: "account-info" */ "@/components/UserProfile/AccountInfo"
  );
const ChangePassword = () =>
  import(
    /* webpackChunkName: "change-password" */ "@/components/UserProfile/ChangePassword"
  );

export default {
  name: "user-profile",
  components: {
    AccountInfo,
    ChangePassword,
  },
  props: [],
  data() {
    return {
      loading: false,
      selectedTab: 0,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo/GET_USER_INFO",
    }),
    tabComponentName() {
      let tabName;
      switch (this.selectedTab) {
        case 0:
          tabName = "AccountInfo";
          break;
        case 1:
          tabName = "ChangePassword";
          break;
      }
      return tabName;
    },
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    ...mapActions({
      setUserInfo: "userInfo/SET_USER_INFO",
    }),
    getUserInfo() {
      this.loading = true;
      UserInfoService.getUserInfo().then((res) => {
        this.setUserInfo(res.data.data);
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "UserProfile";
</style>
